@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-pretendard text-15 text-gray-1-active;
  }
  button,
  input,
  textarea {
    @apply focus:outline-none;
  }

  * {
    @apply focus-visible:outline-none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: normal;
  letter-spacing: -0.3px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and 엣지 */
  scrollbar-width: none; /* 파이어폭스 */
}

/* 얇게 만들려는 스크롤바에 클래스 이름을 추가 */
.thin-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
}

/* input 자동완성 사용 시 생기는 하늘색 배경 제거 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit;
  font-size: inherit;
  transition: background-color 5000s ease-in-out 0s;
}

/* 로딩 스피너 */
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-spin {
  animation: spin 1s cubic-bezier(0, 0.01, 0.3, 0.67) infinite;
}

.loader-shadow {
  box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.2);
}

.rerun-animation {
  animation: rerun-flash 0.5s ease-in-out;
}
.run-animation {
  animation: run-flash 0.5s ease-in-out;
}

@keyframes run-flash {
  0% {
    background-color: white;
  }
  30% {
    background-color: rgb(76, 76, 76);
  }
  100% {
    background-color: white;
  }
}

@keyframes rerun-flash {
  0% {
    background-color: white;
  }
  30% {
    background-color: rgb(76, 76, 76);
  }
  100% {
    background-color: white;
  }
}

@keyframes twinkling {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.twinkling-animation {
  animation: twinkling 0.8s infinite ease-in-out;
}

/* RangePicker button bug fixed */
.ant-btn-primary {
  background-color: #1677ff !important;
}
